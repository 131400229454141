import { useStaticQuery, graphql } from "gatsby"

export const usePrismShopOnline = () => {
  const prismic = useStaticQuery(
    graphql`
      query {
        prismicShopOnlinePage {
          uid
          data {
            body {
              ... on PrismicShopOnlinePageBodyMetas {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                  image_share {
                    url
                  }
                }
              }
              ... on PrismicShopOnlinePageBodyBanner {
                primary {
                  title {
                    text
                  }
                  title_filled {
                    text
                  }
                  description {
                    html
                  }
                  image {
                    url
                    lg {
                      url
                    }
                    md {
                      url
                    }
                    sm {
                      url
                    }
                    xs {
                      url
                    }
                  }
                }
              }
              ... on PrismicShopOnlinePageBodyStorytelling {
                primary {
                  storytelling_description {
                    html
                  }
                }
              }
              ... on PrismicShopOnlinePageBodyShops {
                primary {
                  region_name {
                    text
                  }
                }
                items {
                  shop_url {
                    text
                  }
                  shop_image {
                    url
                  }
                  shop_name {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return prismic.prismicShopOnlinePage
}
