import React from "react"

import CinsaLogo from "../../../components/svg/commons/cinsa_logo"

export const ShopOnlineStorytelling = ({ storytelling }) => {
  return (
    <div className="so-storytelling">
      <CinsaLogo text_visible="false" className="so-storytelling__logo" />
      <div className="so-storytelling__text" dangerouslySetInnerHTML={{ __html: storytelling.storytelling_description.html }} />
    </div>
  )
}
