import React from "react"
import { Picture } from "react-responsive-picture"

export const ShopOnlineShops = ({ shopList }) => {
  let shops
  let zone
  let titleArr
  let title

  return (
    <div className="shop_zone">
      <div className="shop_zone_title">
        {shopList.map((item, index) => {
          zone = item.primary
          shops = item.items
          titleArr = zone.region_name.text.split(" ")
          const total = titleArr.length
          for (let i = 0; i < total; i++) {
            if (i === total - 1) {
              titleArr[i] = `<strong>${titleArr[i]}</strong>`
            } else {
              titleArr[i] = `<span>${titleArr[i]}</span>`
            }
          }
          title = titleArr.join(" ")
          return (
            <div key={`region_${index}`}>
              <p dangerouslySetInnerHTML={{ __html: title }} />
              <div className="so-shops">
                {shops.map((shop, key) => {
                  return (
                    <a key={key} className="so-shops__item tag-shops-item" data-label={shop.shop_name.text} href={shop.shop_url.text} target="_blank">
                      <Picture className="so-shops__item-image" src={shop.shop_image.url} alt={shop.shop_image.alt} />
                    </a>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
