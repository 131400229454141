import React from "react"

import { Picture } from "react-responsive-picture"
import { TitleStroke } from "../../../components/_titles/_header_title_stroke"
import SvgSeemapCurve from "../../../components/svg/dealers/seemap_curve"

export const ShopOnlineBanner = ({ banner }) => {
  return (
    <div className="so-banner">
      <div className="so-banner__bg">
        <Picture
          className="so-banner__img"
          sources={[
            {
              srcSet: banner.image.xs.url,
              media: "(max-width: 767px)",
            },
            {
              srcSet: banner.image.sm.url,
              media: "(max-width: 1023px)",
            },
            {
              srcSet: banner.image.md.url,
              media: "(max-width: 1280px)",
            },
            {
              srcSet: banner.image.lg.url,
              media: "(max-width: 1366px)",
            },
            {
              srcSet: banner.image.url,
              media: "(min-width: 1367px)",
            },
          ]}
        />
        <SvgSeemapCurve />
      </div>
      <div className="so-banner__header">
        <TitleStroke textStroke={banner.title.text} text={banner.title_filled.text} />
        <p className="so-banner__description" dangerouslySetInnerHTML={{ __html: banner.description.html }} />
      </div>
    </div>
  )
}
