import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { usePrismShopOnline } from "./../hooks/use_prism_shop_online"
import { ShopOnlineBanner } from "./../content/shop_online/banner/shop_online_banner"
import { ShopOnlineStorytelling } from "./../content/shop_online/storytelling/shop_online_storytelling"
import { ShopOnlineShops } from "./../content/shop_online/shops/shop_online_shops"
import "../styles/pages/shop_online/shop_online.scss"

const ShopOnline = () => {
  const shopOnlineQuery = usePrismShopOnline()
  let metasData
  let bannerData
  let storytellingData
  let shopsData = []

  shopOnlineQuery.data.body.forEach(item => {
    switch (item.__typename) {
      case "PrismicShopOnlinePageBodyMetas":
        metasData = item.primary
        break
      case "PrismicShopOnlinePageBodyBanner":
        bannerData = item.primary
        break
      case "PrismicShopOnlinePageBodyStorytelling":
        storytellingData = item.primary
        break
      case "PrismicShopOnlinePageBodyShops":
        shopsData.push(item)
        break
    }
  })

  // console.log(metasData)

  return (
    <Layout>
      {/* <Head title={metasData.title.text} pathname={`/${shopOnlineQuery.uid}`} description={metasData.description.text} image={metasData.image_share.url} /> */}
      <ShopOnlineBanner banner={bannerData} />
      <ShopOnlineStorytelling storytelling={storytellingData} />
      <ShopOnlineShops shopList={shopsData} />
    </Layout>
  )
}

export default ShopOnline
